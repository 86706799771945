.rules-container {
    width: 50%;

    margin-left: auto;
    margin-right: auto;

    background-color: #1E003F;

    padding: 2.5%;
    border-radius: 20px;
    border: 3px solid #424242;
    color: white;
}

.rules-container input {
    border: none;
}

.rules-container input[type=submit] {
    float: right;
    font-size: 20px;

    background-color: transparent;
    color: white;
    cursor: pointer;

    border: 2px solid transparent;

    border-radius: 20px;
}

.rules-container input[type=submit]:hover {
    border: 2px solid white;
}

.rules-container ul, .rules-container p {
    margin-bottom: 0;
    color: white;
}

.rules-container h1 {
    color: white;
    font-family: Agrandir;
}

@media screen and (max-width: 426px) {
    .rules-container {
        font-size: 10px;
    }

    .rules-container ul {
        padding-left: 10%;
    }
}