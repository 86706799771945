.centerify {
    /* height: 100%; */
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

@keyframes underline-effect {
    0%, 100% {
        background-size: 0% 0.1em;
        background-position-x: 0%;
    }
    49.9% { background-position-x: 0%; }
    50% {
        background-size: 100% 0.1em;
        background-position-x: 100%;
    }
    99.9% { background-position-x: 100%; }
}

.header {
    text-align: center;
    color: var(--primary);
    font-family: Aurora;
    margin: 0;

    font-size: 25vh;

    background-image: linear-gradient(var(--primary), var(--primary));
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 100%;

    background-repeat: no-repeat;
}

.header:nth-child(1) { animation: underline-effect 3s ease-in-out forwards infinite; }
.header:nth-child(2) {
    animation: underline-effect 3s ease-in-out forwards infinite;
    animation-delay: 0.9s;
}

.contact {
    margin-top: 1%;

    text-align: center;

    color: white;
    font-family: sans-serif;
    font-weight: bolder;
    font-size: 3vh;

    padding: 1vh;

    border: 5px solid var(--primary);
    border-radius: 2.5vh;

    background-color: transparent;
    cursor: pointer;
}

@media screen and (max-width: 426px) {
    .header {
        font-size: 50px;
    }
}

.content-blocker {
    position: absolute;
    pointer-events: none;

    height: 100%;
    width: 100vw;
    background-color: white;
}

.content-blocker h1 {
    position: absolute;

    left: 50%;
    transform: translateX(-50%);
}