.terminal-container-higher {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;

    padding-top: 2%;
}

.reg-folder {
    position: absolute;

    /* heig */
    width: 30vh;

    text-decoration: none;
}

.reg-folder > div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.reg-folder .folder-img {
    height: 20vh;
    aspect-ratio: 1/1;
}

.reg-folder:nth-child(1) {
    top: 0;
    left: 10%;
}

.reg-folder:nth-child(2) {
    top: 35%;
    left: 10%;
}

.reg-folder:nth-child(3) {
    top: 70%;
    left: 10%;
}

.reg-folder:nth-child(4) {
    top: 0;
    right: 10%;
}

.reg-folder:nth-child(5) {
    top: 35%;
    right: 10%;
}

.reg-folder:nth-child(6) {
    top: 70%;
    right: 10%;
}