:root {
    --tint: rgba(21, 14, 96, 0.48);
    --primary: #FFC8CB;
}

body {
    overflow-x: hidden;
}

@font-face {
    font-family: Agrandir;
    src: url('assets/Agrandir-GrandHeavy.otf');
}

@font-face {
    font-family: Aurora;
    src: url('assets/Aurora.otf');
}

.background {
    width: 100vw;

    background-image: url('assets/background.jpeg');
    background-color: var(--tint);
    background-size: cover;
    background-position-y: center;
    background-blend-mode: darken;
    background-attachment: fixed;
}

.home-icon {
    height: 5vh;
    margin: 1.25vh;
    margin-left: 5vh;
}

.navbar {
    width: 100%;
    height: 7.5vh;

    background-color: rgba(23, 11, 58, 0.7);
}

.navbar ul {
    height: 100%;

    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0
}

.navbar ul li {
    height: 100%;
}

.navlink {
    height: 100%;
    padding: 0 100px 0 100px;

    color: white;
    font-family: Agrandir;

    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.navlink:hover,
.navlink:visited {
    color: white;
}

.content {
    min-height: 92.5vh;
}

.active-page-a { color: var(--primary) !important; }

@keyframes slide {
    from { left: -100vw; }
    to { left: 0; }
}

.slide {
    animation: slide 1s ease-in-out forwards;
}

.buffer { height: 100px; }

@media screen and (max-width: 426px) {
    .navbar {
        height: 7.5vh;
    }

    .home-icon {
        height: 3vh;
        margin: 2.25vh;
    }

    .navlink {
        padding: 0 10px 0 10px;
        font-size: 8px;
    }
}